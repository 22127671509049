import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit
{
	form 		   : FormGroup;
	busy 		   : boolean  = false;
	serverErrors   : string[] = [];

	constructor
	(
		private loginService   : LoginService,
		private errorResponse  : ErrorResponseHandlerService,
		private router 		   : Router,
	)
	{
		this.buildForm();
	}

	ngOnInit()
	{
	}

	onFormSubmit()
	{
		this.busy = true;

		this.loginService.login(this.form.value).subscribe(response =>
		{
			this.busy 			= false;
			this.serverErrors	= [];
			this.form.reset();
			window.localStorage.setItem("token", response.access_token);
			window.localStorage.setItem("firstTimeLogin", response.firstTimeLogin);

			if(response.firstTimeLogin)
			{
				this.router.navigate(["/app/profile"]);
			}
			else
			{
				this.router.navigate(["/app/patients"]);
			}
		},
		(response : Response) =>
		{
			this.busy = false;
			this.serverErrors = this.errorResponse.handleHttpError(response);
		});
	}

	buildForm()
	{
		this.form = new FormGroup({
			email 	 : new FormControl("", [Validators.required, Validators.email]),
			password : new FormControl("", Validators.required),
		});
	}
}
