import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ResetPasswordService
{
	private RESET_PASSWORD_URL = API_BASE_URL + "auth/recoverPassword";

	constructor(private http : Http)
	{ 
	}

	resetPassword(params)
	{
		return this.http.post(this.RESET_PASSWORD_URL, params).pipe(map(el => el.json()))
	}
}
