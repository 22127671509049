import { Injectable } from "@angular/core";
import { CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
	providedIn: "root"
})
export class LoggedInGuard implements CanActivateChild
{
	constructor(private router : Router)
	{
	}

	canActivateChild
	(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean
	{
		let token = Boolean(localStorage.getItem("token"));

		if(token)
		{
			return true;
		}
		
		this.router.navigate(["auth/login"]);
	}
}
