import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatPaginator } from "@angular/material";
import { ActivatedRoute, Router } from "../../../../node_modules/@angular/router";
import { PatientService } from "../../services/patient.service";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

interface TreatmentPlan
{
	id               : number;
	patientId        : number;
	therapyEndDate   : string;
	therapyStartDate : string;
	treatmentDetail  : string;
	treatmentTitle   : string;
	created_at       : string;
	updated_at       : string;
}

@Component({
	selector: "app-patient-treatment-plan-history",
	templateUrl: "./patient-treatment-plan-history.component.html",
	styleUrls: ["./patient-treatment-plan-history.component.css"]
})
export class PatientTreatmentPlanHistoryComponent implements OnInit
{
	@ViewChild(MatPaginator) paginator: MatPaginator;
	busy 	   		 : boolean = false;
	dataSource 		 : MatTableDataSource <TreatmentPlan>;
	patient			 : any = {};
	// displayedColumns : string[] = ["id", "therapy", "startDate", "endDate", "viewPlan"];
	displayedColumns : string[] = ["id", "therapy", "startDate", "endDate"];

	constructor
	(
		private activatedRoute : ActivatedRoute,
		private patientService : PatientService,
		private router 		   : Router,
		private errorResponse  : ErrorResponseHandlerService,
	)
	{
	}
	
	ngOnInit()
	{
		this.activatedRoute.params.subscribe(params => this.getTreatmentPlans(params.patientId));
	}

	getTreatmentPlans(patientId : number)
	{
		this.busy = true;
		this.patientService.getTreatmentPlans(patientId).subscribe((response : any) =>
		{
			this.busy = false;
			let json = response.json();
			this.patient 	= json.patient;
			this.dataSource = new MatTableDataSource(json.treatmentPlans);
			this.dataSource.paginator = this.paginator;
		},
		(error : Response) =>
		{
			this.busy = false;
			this.errorResponse.handleHttpError(error);
		});
	}

	applyFilter(filterValue: string)
	{
		this.dataSource.filter = filterValue.trim().toLowerCase();
	}
}
