import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { MatInputModule } from "@angular/material/input";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatSidenavModule } from "@angular/material/sidenav";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSelectModule } from "@angular/material/select";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatListModule } from "@angular/material/list";
import { MatSliderModule } from "@angular/material/slider";
import { MatNativeDateModule } from "@angular/material";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LayoutModule } from "@angular/cdk/layout";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { ChartModule } from "angular-highcharts";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';

import "hammerjs";

import { LoginComponent } from "./login/login.component";
import { ProfileComponent } from "./profile/profile.component";
import { PatientListComponent } from "./patient-list/patient-list.component";
import { AddPatientComponent } from "./add-patient/add-patient.component";
import { AppointmentsComponent } from "./appointments/appointments.component";
import { TherapiesComponent } from "./therapies/therapies.component";
import { PatientProfileComponent } from "./patient-profile/patient-profile.component";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { RegisterComponent } from './register/register.component';
import { TreatmentPlanComponent } from './treatment-plan/treatment-plan.component';
import { PatientTreatmentPlanHistoryComponent } from './patient-treatment-plan-history/patient-treatment-plan-history.component';
import { RegisterService } from "./register/register.service";
import { LoginService } from "./login/login.service";
import { ProfileService } from "./profile/profile.service";
import { DialogConfirmComponent } from './dialog-confirm/dialog-confirm.component';
import { ErrorResponseHandlerService } from "../services/error-response-handler.service";
import { ForgotPasswordService } from "./forgot-password/forgot-password.service";
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TreatmentPlanService } from "./treatment-plan/treatment-plan.service";
import { PatientProfileService } from "./patient-profile/patient-profile.service";
import { DialogAppointmentComponent } from './dialog-appointment/dialog-appointment.component';

@NgModule({
    declarations: [
        LoginComponent,
        ProfileComponent,
        PatientListComponent,
        AddPatientComponent,
        AppointmentsComponent,
        TherapiesComponent,
        PatientProfileComponent,
        MainNavComponent,
        ForgotPasswordComponent,
        RegisterComponent,
        TreatmentPlanComponent,
        PatientTreatmentPlanHistoryComponent,
        DialogConfirmComponent,
        ResetPasswordComponent,
        DialogAppointmentComponent,
    ],
    imports: [
        RouterModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatToolbarModule,
        MatMenuModule,
        MatSidenavModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatTableModule,
        MatPaginatorModule,
        MatListModule,
        MatSliderModule,
        MatNativeDateModule,
        NgxMaterialTimepickerModule,
        NgxMaterialTimepickerModule.forRoot(),
        MatAutocompleteModule,
        LayoutModule,
        ChartModule,
        FormsModule, 
        ReactiveFormsModule,
        HttpModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatDialogModule
    ],
    exports: [
        LoginComponent,
        ProfileComponent,
        PatientListComponent,
        AddPatientComponent,
        AppointmentsComponent,
        TherapiesComponent,
        PatientProfileComponent,
        MainNavComponent,
        ForgotPasswordComponent,
        RegisterComponent,
        DialogConfirmComponent
    ],
    entryComponents: [
        DialogConfirmComponent,
        DialogAppointmentComponent
    ],
    providers: [
        LoginService,
        RegisterService,
        ForgotPasswordService,
        ProfileService,
        ErrorResponseHandlerService,
        TreatmentPlanService,
        PatientProfileService
    ]
})
export class PagesModule
{
}