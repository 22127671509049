import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { API_BASE_URL } from "../constants/api.constants";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class PatientService
{
	private ADD_PATIENT_URL    				 = API_BASE_URL + "patient/add";
	private GET_PATIENTS_URL   				 = API_BASE_URL + "patient/getAll";
	private DELETE_PATIENT_URL 				 = API_BASE_URL + "patient/delete";
	private ADD_THERAPIES_URL  				 = API_BASE_URL + "patient/addTherapies";
	private GET_PATIENT_TREATMENT_PLANS_URL  = API_BASE_URL + "patient/getTreatmentPlans";
	private ADD_APPOINTMENTS_URL  			 = API_BASE_URL + "patient/addAppointments";
	private GET_TREATMENT_PLAN_DETAILS  	 = API_BASE_URL + "patient/getTreatmentPlanDetails";
	private GET_PATIENT_PROFILE  	 		 = API_BASE_URL + "patient/profile";
	private DELETE_THERAPY_URL 				 = API_BASE_URL + "patient/deleteTherapy";

	constructor(private http : Http)
	{
	}

	submitPatientDetails(patientDetails)
	{
		return this.http.post(this.ADD_PATIENT_URL, { patientDetails }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}

	submitTherapyDetails(params)
	{
		return this.http.post(this.ADD_THERAPIES_URL, params , {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}
	
	getAllPatients()
	{
		return this.http.get(this.GET_PATIENTS_URL, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		}).pipe (
			map(el => el.json())
		);
	}

	deletePatient(patientId : number)
	{
		return this.http.post(this.DELETE_PATIENT_URL, { patientId }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}

	getTreatmentPlans(patientId : number)
	{
		return this.http.post(this.GET_PATIENT_TREATMENT_PLANS_URL, { patientId }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}

	addAppointments(params)
	{
		return this.http.post(this.ADD_APPOINTMENTS_URL, params, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}
	
	getTreatmentPlanDetails(patientId)
	{
		return this.http.post(this.GET_TREATMENT_PLAN_DETAILS, { patientId }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}
	
	getPatientDetails(patientId : number)
	{
		return this.http.post(this.GET_PATIENT_PROFILE, { patientId }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		});
	}

	deleteTherapy(therapyId)
	{
		return this.http.post(this.DELETE_THERAPY_URL, { therapyId }, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		})
		.pipe(map(el => el.json()));
	}
}
