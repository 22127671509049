import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormArray, Validators } from "@angular/forms";
import { PatientService } from "../../services/patient.service";
import { Router, ActivatedRoute } from "../../../../node_modules/@angular/router";
import { MatSnackBar } from '@angular/material';
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import * as moment from "moment";
import { MatDialog } from '@angular/material';
import { DialogAppointmentComponent } from "../dialog-appointment/dialog-appointment.component";


@Component({
    selector: "app-therapies",
    templateUrl: "./therapies.component.html",
    styleUrls: ["./therapies.component.css"]
})
export class TherapiesComponent implements OnInit
{
    busy            : boolean = false;
    therapiesAdded  : boolean = false;
    patientId       : number;
    form            : FormGroup;
    serverErrors    : string[] = [];
    therapyOptions = ["Topicals", "Corticosteroids", "PUVA / UVB", "Targeted Synthetic DMARDs", "NSAIDS", "Lifestyle changes", "Biologic Agents", "Conventional Synthetic DMARDs"];
    therapyGoalsDetail : string[] = [
        "be free of pain",
        "be free of itching", 
        "no longer have burning sensations on skin",
        "be healed of all skin defects",
        "be able to sleep better",
        "feel less depressed",
        "experience a greater enjoyment of life",
        "have no fear that the disease will become worse",
        "be able to lead a normal everyday life",
        "be more productive in everyda",
        "be less of a burden to relatives and",
        "be able to engage in normal leisure activities",
        "be able to lead a normal working life",
        "be able to have more contact with other people",
        "be comfortable showing yourself more in public",
        "be less burdened in your partnership",
        "be able to have a normal sex life",
        "be less dependent on doctor and clinic visits need less time for daily",
        "treatment",
        "have fewer out-of-pocket treatment expenses",
        "have fewer side effects",
        "find a clear diagnosis and therapy",
        "have confidence in the therapy",
        "get better skin quickly",
        "regain control of the disease",
    ];

    constructor
    (
        private patientService : PatientService,
        private activatedRoute : ActivatedRoute,
        private router         : Router,
        private snackBar       : MatSnackBar,
        private errorResponse  : ErrorResponseHandlerService,
        private dialog         : MatDialog,
    )
    {
        this.buildForm();
    }
    
    ngOnInit()
    {
        this.activatedRoute.params.subscribe(params =>
        {
            this.patientId = params.patientId;
            this.form.get("patientId").setValue(params.patientId);
        });
    }

    onSelectEvaluationOption(event)
    {
        if(event.value == '1')
        {
            let dialogRef = this.dialog.open(DialogAppointmentComponent, {
                width : "850px",
                data  : { patientId : this.patientId }
            });
        }
    }

    onFormSubmit()
    {
        if(this.form.valid)
        {
            this.busy    = true;
            let formData = this.formatData();
            this.serverErrors = [];

            this.patientService.submitTherapyDetails(formData).subscribe(response =>
            {
                this.busy           = false;
                this.resetForm();
                this.snackBar.open("Therapies have been added successfully", "Dismiss", {
                    duration: 6000
                });
                this.router.navigate(["/app/patientProfileData/" + this.patientId]);
                
            },
            (response : any) =>
            {
                this.busy           = false;
                this.serverErrors = this.errorResponse.handleHttpError(response);
            });
        }
    }

    formatData()
    {
        let formData = this.form.value;

        formData.therapies.forEach(therapy =>
        {
            therapy.startDate      = therapy.startDate ? moment(therapy.startDate).format("YYYY-MM-DD") : null;
            therapy.evaluationDate = therapy.evaluationDate ? moment(therapy.evaluationDate).format("YYYY-MM-DD") : null;
        });

        return formData;
    }
    
    toggleGoalsForTherapy(therapy : FormGroup)
    {
        let value = therapy.get("hasGoals").value;
        therapy.get("hasGoals").setValue(!value);
    }
    
    buildForm()
    {
        this.form = new FormGroup({
            patientId : new FormControl("", Validators.required),
            therapies : new FormArray([])
        });

        this.addTherapy();
    }

    addTherapy()
    {
        (<FormArray>this.form.get("therapies")).push(new FormGroup({
            therapy        : new FormControl("", Validators.required),
            details        : new FormControl(""),
            startDate      : new FormControl("", Validators.required),
            evaluationDate : new FormControl(""),
            evaluationType : new FormControl("", Validators.required),
            hasGoals       : new FormControl(false),
            goals          : new FormGroup({
                details           : new FormControl(""),
                anticipatedTiming : new FormControl(false),
                results           : new FormControl(""),
            }),
            futureOptionsShouldShow : new FormControl(false),
            futurePlanDetails       : new FormControl(""),
            comments                : new FormControl(""),
        }));
    }

    filterOptions(control : FormControl)
    {
        return this.therapyOptions.filter(option => option && option.toLowerCase().indexOf(control.value.toLowerCase()) > -1);
    }

    removeTherapy(index : number)
    {
        (<FormArray>this.form.get("therapies")).removeAt(index);
    }

    onNextBtnClick()
    {
        this.router.navigate(["/app/appointments/" + this.patientId]);
    }

    private resetForm()
    {
        let therapies      = <FormArray>this.form.get("therapies");
        let controlsLength = therapies.controls.length;

        for(let i = 0; i < controlsLength; i++)
        {
            this.removeTherapy(0);
        }

        this.addTherapy();
    }
}
