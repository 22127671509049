import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { PagesModule } from "./pages/pages.module";
import { routes } from "./routes";

import { AppComponent } from "./app.component";

@NgModule({
    declarations: [
        AppComponent
   ],
    imports: [
        BrowserModule,
        RouterModule.forRoot(routes, {
            enableTracing: false,
            useHash: true
        }),
        PagesModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule
{ 
}