import { Routes } from "@angular/router";

import { LoginComponent } from "../pages/login/login.component";
import { ForgotPasswordComponent } from "../pages/forgot-password/forgot-password.component";
import { MainNavComponent } from "../pages/main-nav/main-nav.component";
import { ProfileComponent } from "../pages/profile/profile.component";
import { PatientListComponent } from "../pages/patient-list/patient-list.component";
import { PatientProfileComponent } from "../pages/patient-profile/patient-profile.component";
import { AddPatientComponent } from "../pages/add-patient/add-patient.component";
import { AppointmentsComponent } from "../pages/appointments/appointments.component";
import { TherapiesComponent } from "../pages/therapies/therapies.component";
import { RegisterComponent } from '../pages/register/register.component';
import { TreatmentPlanComponent } from '../pages/treatment-plan/treatment-plan.component';
import { PatientTreatmentPlanHistoryComponent } from '../pages/patient-treatment-plan-history/patient-treatment-plan-history.component';
import { ResetPasswordComponent } from '../pages/reset-password/reset-password.component';
import { LoggedInGuard } from "../guards/logged-in.guard";
import { ProfileSetGuard } from "../guards/profile-set.guard";

export const routes: Routes = [
    {
        path: "auth/login",
        component: LoginComponent
    },
    {
        path: "auth/forgotPassword",
        component: ForgotPasswordComponent
    },
    {
        path: "auth/register",
        component: RegisterComponent
    },
    {
        path: "auth/resetPassword/:token",
        component : ResetPasswordComponent 
    },
    {
        path: "app",
        component: MainNavComponent,
        canActivateChild : [LoggedInGuard],
        children: [
            {
                path: "profile",
                component: ProfileComponent
            },
            {
                path: "patients",
                component: PatientListComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "patient/add",
                component: AddPatientComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "patientProfileData/:patientId",
                component: PatientProfileComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "appointments/:patientId/:newAccount",
                component: AppointmentsComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "appointments/:patientId",
                component: AppointmentsComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "therapies/:patientId",
                component: TherapiesComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "treatmentPlan/:patientId",
                component: TreatmentPlanComponent,
                canActivate : [ProfileSetGuard]
            },
            {
                path: "patientTreatmentHistory/:patientId",
                component: PatientTreatmentPlanHistoryComponent,
                canActivate : [ProfileSetGuard]
            }
        ]
    },
    {
        path: "**",
        redirectTo: "/auth/login"
    },
]