import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: "root"
})
export class TreatmentPlanService
{
    private SEND_MAIL_URL : string = API_BASE_URL + "patient/sendChart";

    groupingPluginInitialzed : boolean = false;

    constructor(private http : Http)
	{ 
	}

    mailChart(img, patientId)
    {
        let headers = new Headers({
			"Authorization" : "Bearer " + localStorage.getItem("token")
        });
        
        return this.http.post(this.SEND_MAIL_URL, {imageCode : img, patientId}, { headers }).pipe (
			map(el => el.json())
		);
    }
}
