import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ForgotPasswordService } from "./forgot-password.service";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

@Component({
	selector: "app-forgot-password",
	templateUrl: "./forgot-password.component.html",
	styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent implements OnInit
{
	form 		   : FormGroup;
	busy 		   : boolean  = false;
	serverErrors   : string[] = [];
	emailSend : boolean = false;

	constructor
	(
		private forgotPasswordService : ForgotPasswordService,
		private errorResponse 		  : ErrorResponseHandlerService
	)
	{
		this.buildForm();
	}

	ngOnInit()
	{
	}

	onFormSubmit()
	{
		this.busy = true;

		this.forgotPasswordService.forgotPassword(this.form.value).subscribe(response =>
		{
			this.busy = false;
			this.emailSend = true;
		},
		(response : Response) =>
		{
			this.busy = false;
			this.serverErrors = this.errorResponse.handleHttpError(response);
		});
	}

	buildForm()
	{
		this.form = new FormGroup({
			email : new FormControl("", Validators.required)
		});
	}
}
