import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ResetPasswordService } from "./reset-password.service";
import { ActivatedRoute } from "@angular/router";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit 
{

	form 		   			: FormGroup;
	busy 		   			: boolean  = false;
	serverErrors   			: string[] = [];
	requestDone    			: boolean = false;
	resetPasswordSuccess    : boolean = false;
	loginLink     			: boolean = false;

	
	constructor
	(
		private resetPasswordService : ResetPasswordService,
		private errorResponse 		  : ErrorResponseHandlerService,
		private activatedRoute : ActivatedRoute,
	)
	{
		this.buildForm();
	}
	
	ngOnInit()
	{
		this.activatedRoute.params.subscribe(params =>
		{
			this.form.get("activationToken").setValue(params.token);
		});
	}

	onFormSubmit()
	{
		this.busy = true;
		this.resetPasswordService.resetPassword(this.form.value).subscribe(response =>
		{
			this.busy 					= false;
			this.serverErrors			= [];
			this.requestDone    		= true;
			this.resetPasswordSuccess	= true;
			this.loginLink   			= true;
		},
		(response : Response) =>
		{
			this.busy = false;
			this.serverErrors = this.errorResponse.handleHttpError(response);
		});
	}

	buildForm()
	{
		this.form = new FormGroup({
			password 			  : new FormControl("", Validators.required),
			password_confirmation : new FormControl("", Validators.required),
			activationToken 	  : new FormControl("", Validators.required),
		});
	}
	
}
