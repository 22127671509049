import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent implements OnInit
{
	constructor (private router : Router)
	{
	}
	
	ngOnInit()
	{
		this.router.events.subscribe((evt) =>
		{
			if (!(evt instanceof NavigationEnd))
			{
                return;
			}
			
			let html = document.getElementsByTagName("html")[0];
			let body = document.getElementsByTagName("body")[0];
			html.style.height = "auto";
			body.style.height = "auto";
			window.scrollTo(0, 0);
			
			setTimeout(() =>
			{
				html.style.height = "100%";
				body.style.height = "100%";
			}, 200);
        });
	}
}
