import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class LoginService
{
	private LOGIN_URL = API_BASE_URL + "auth/login";

	constructor(private http : Http)
	{ 
	}

	login(params)
	{
		return this.http.post(this.LOGIN_URL, params).pipe(map(el => el.json()))
	}
}
