import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Router } from "@angular/router";
import sweetAlert from "sweetalert";

@Injectable({
	providedIn: "root"
})
export class ErrorResponseHandlerService
{
	private response : Response;

	constructor
	(
		private router : Router
	)
	{
	}

	handleHttpError(response : Response)
	{
        switch(response.status)
        {
            case 400:
                return response.json();
            case 401:
                this.router.navigate(["/"], {replaceUrl: true});
                break;
            case 0:
                this.showNetworkError();
                break;
            default:
                return response.json().message;
        }
	}

	isNetworkError()
    {
        return this.response.status === 0;
    }

    showNetworkError()
    {
        sweetAlert("Network Error", "Oops! It seems like your internet connection is down. Please double check and try again", "error");
    }

    showServerError()
    {
        sweetAlert("Server Error", "Oops! There was some error while processing the request. Please try again later", "error");
    }
}
