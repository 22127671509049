import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
	selector: "app-dialog-appointment",
	templateUrl: "./dialog-appointment.component.html",
	styleUrls: ["./dialog-appointment.component.css"]
})
export class DialogAppointmentComponent implements OnInit
{
	patientId : number;

	constructor
	(
		@Inject(MAT_DIALOG_DATA) private data : { patientId : number }, 
		private dialogRef : MatDialogRef <DialogAppointmentComponent>,
	)
	{
		this.patientId = data.patientId;
	}

	ngOnInit()
	{
	}
	
	onFormSubmit(value)
	{
		this.dialogRef.close(false);
	}
}
