import { Injectable } from "@angular/core";
import { Http } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class RegisterService
{
	private REGISTER_URL = API_BASE_URL + "auth/register";

	constructor(private http : Http)
	{ 
	}

	register(params)
	{
		return this.http.post(this.REGISTER_URL, params).pipe(map(el => el.json()))
	}
}
