import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import * as Highcharts from "highcharts";
import { PatientService } from "../../services/patient.service";
import { ActivatedRoute } from "@angular/router";
import * as HighchartsGroupedCategories from "highcharts-grouped-categories";
import * as HighchartsExporting from "highcharts-exporting";
import * as moment from "moment";
import * as html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import * as printHtmlElement from "print-html-element";
import { MatSnackBar } from '@angular/material';
import { Response } from "@angular/http";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { TreatmentPlanService } from "./treatment-plan.service";

@Component({
    selector: "app-treatment-plan",
    templateUrl: "./treatment-plan.component.html",
    styleUrls: ["./treatment-plan.component.css"]
})
export class TreatmentPlanComponent implements OnInit
{
    @ViewChild("chart") chartElement : ElementRef; 
    @ViewChild("chartWrapper") chartWrapper : ElementRef; 
    @ViewChild("downloadElement") downloadElement : ElementRef; 
    
    busy          : boolean = false;
    chart         : any;
    patient       : any = {};
    creationDate  : string = "";
    
    constructor
    (
        private patientService       : PatientService,
        private activatedRoute       : ActivatedRoute,
        private errorResponse        : ErrorResponseHandlerService,
        private treatmentPlanService : TreatmentPlanService,
        private snackBar             : MatSnackBar,
    )
    {
        if(!treatmentPlanService.groupingPluginInitialzed)
        {
            HighchartsGroupedCategories(Highcharts);
            HighchartsExporting(Highcharts);
            treatmentPlanService.groupingPluginInitialzed = true;
        }
    }
    
    ngOnInit()
    {
        this.initPage();
    }
    
    initPage()
    {
        this.activatedRoute.params.subscribe(params =>
        {
            this.busy = true;
            
            this.patientService.getTreatmentPlanDetails(params.patientId).subscribe((response : any) =>
            {
                this.busy    = false;
                response     = response.json();
                this.patient = response.patient;
                this.creationDate = response.startingDate;
                this.generateChart(response.therapyResult);
            },
            (response : Response) =>
            {
                this.busy = false;
                this.errorResponse.handleHttpError(response);
            });
        });
    }

    printChart()
    {
        html2canvas(this.chartWrapper.nativeElement).then((canvas : HTMLCanvasElement) =>
        {
            let base64 = canvas.toDataURL();
            let html   = `<img src="${base64}">`;
            printHtmlElement.printHtml(html);
        });
    }

    sendMail()
    {
        this.busy = true;
        
        html2canvas(this.chartWrapper.nativeElement).then((canvas : HTMLCanvasElement) =>
        {
            let base64 = canvas.toDataURL();
            this.treatmentPlanService.mailChart(base64, this.patient.id).subscribe(response =>
            {
                this.busy = false;
                this.snackBar.open("Chart has been emailed successfully", "Dismiss", {
                    duration: 6000
                });
            },
            (error : Response) =>
            {
                this.busy = false;
                this.snackBar.open("Couldn't send the chart at the moment", "Dismiss", {
                    duration: 6000
                });
            });
        });
    }
        
    generateChart(therapyResults)
    {
        let chartMonths   = this.generateChartMonths();
        let therapyLabels = this.patient.therapies.map(therapy => `${therapy.therapy}`);
        let plotLines     = this.getPlotLines(chartMonths, this.patient.appointment);

        this.renderChart ( chartMonths, therapyLabels, therapyResults, plotLines );
    }
    
    generateChartMonths()
    {
        let date          = moment(this.creationDate, "YYYY-MM-DD");
        let patientMonth  = date.month();
        this.creationDate = date.format("DD/MM/YYYY");
        return Array.apply(0, Array(12)).map((_, i : number) =>
        {
            return moment().month(patientMonth++).format("MMM");
        });
    }
    
    renderChart(months : string[], xAxisLabels : string[], seriesData, plotLines)
    {
        let chart = Highcharts.chart(this.chartElement.nativeElement, {
            chart: {
                renderTo: "container",
                type: "bar",
                marginBottom: 80,
            }, 
            title: {
                text: "ANNUAL TREATMENT PLAN",
            },
            credits: {
                enabled: false
            },
            xAxis: {
                categories: xAxisLabels
            },
            yAxis: {
                title: {
                    text: " ",
                },
                labels: {
                    rotation: 0,
                    style: {
                        color: "#ffffff",
                    },
                    groupedOptions: [{   
                        style: {
                            color: "#5A6864",
                        },
                        y: -18,
                    }]
                },
                min: 0,
                max: 11,
                opposite: true,
                categories: [
                    {
                        name: "PLANNED",
                        categories: months.slice(0, 3),
                    },
                    {
                        name: "OPTIONS TO ASSESS BASED ON OUTCOME*",
                        categories: months.slice(3),
                    },
                ],
                plotLines : plotLines
            },
            legend: {
                enabled:false,
            },
            plotOptions: {
                bar: {
                    pointPadding: 0,
                    borderWidth: 0,
                    groupPadding: 0
                },
                series: {
                    stacking: "normal",
                    dataLabels: {
                        enabled: true,
                        color: "#fff",
                        style: { fontWeight: "normal", fontSize: "13px"},
                        formatter: function () { return this.series.name },
                        inside: true,
                        zIndex: 100
                    }
                }
            },
            series: seriesData,
            exporting: {
                enabled: false,
                sourceWidth: 1000,
                buttons: { 
                    contextButton: {
                        menuItems: ['printChart', 'separator','downloadJPEG', 'downloadPDF']
                    }
                }
            }
        }, this.renderCustomChartElements.bind(this));
    }

    private renderCustomChartElements(chart)
    {
        let xAxisStartPoint    = chart.yAxis[0].toPixels(0);
        let firstCategoryWidth = 220;
        let xAxisEndPoint      = xAxisStartPoint + firstCategoryWidth;
        chart.renderer.rect (xAxisStartPoint - 35, 55, firstCategoryWidth + 35, 30, 0 ).css({ fill : "#F0F4D9", }).add();
        chart.renderer.rect (xAxisEndPoint, 55, 610, 30, 0 ).css({ fill : "#CDF1F3", }).add();
        chart.renderer.rect ( 0, 55, xAxisStartPoint - 35, 61 , 0).css({ fill : "#68B133", }).add();
        chart.renderer.text("Therapies", 15, 90).css({fill: "#ffffff",}).add();
        chart.renderer.rect ( xAxisStartPoint -35, 86, 2000, 30 , 0).css({ fill : "#7CBF33", }).add();
        chart.renderer.text("Name: First Name Last Name", 0, -25).css({ fill: "#000000"}).add();
    }

    saveImage()
    {
        html2canvas(this.chartWrapper.nativeElement).then((canvas : HTMLCanvasElement) =>
        {
            let anchor = (<HTMLAnchorElement>this.downloadElement.nativeElement);
            anchor.href = canvas.toDataURL();
            anchor.download = "Chart Image.png";
            anchor.click();
        });
    }

    savePDF()
    {
        let pdf = new jsPDF("l");

        html2canvas(this.chartWrapper.nativeElement).then((canvas : HTMLCanvasElement) =>
        {
            let base64 = canvas.toDataURL();
            pdf.addImage(base64, "png", 15, 15);
            pdf.save("Chart.pdf");
        });
    }
    printPage()
    {
        printHtmlElement(this.chartWrapper.nativeElement);
    }

    getPlotLines(chartMonths, appointment)
    {
        if(!appointment)
        {
            return [];
        }

        let plotLineConfig = {
            value: 0,
            color: "#8064A2",
            width: 4,
            zIndex: 101,
            label: {
                text: "",
                rotation: 0, 
                verticalAlign: "bottom",
                y: 20,
                x: -40, 
                style: {
                    fontWeight: "bold",
                }
            }
        };
    
        let appointmentOne        = {...plotLineConfig};
        appointmentOne.value      = chartMonths.indexOf(moment(appointment.firstAppointmentDate, "DD/MM/YYYY").format("MMM"));
        appointmentOne.label.text = "Assess progress<br>" + moment(appointment.firstAppointmentDate, "DD/MM/YYYY").format("DD/MM/YYYY")  + " " + moment(appointment.firstAppointmentTime, "HH:mm:ss").format("HH:mm A");

        let plotLines = [
            appointmentOne
        ];

        if(appointment.secondAppointmentDate && appointment.secondAppointmentTime)
        {
            let secondAppointment = {...plotLineConfig};
            secondAppointment.value      = chartMonths.indexOf(moment(appointment.secondAppointmentDate, "YYYY-MM-DD").format("MMM"));
            secondAppointment.label.text = "Assess progress<br>" + moment(appointment.secondAppointmentDate, "YYYY-MM-DD").format("DD/MM/YYYY")  + " " + moment(appointment.secondAppointmentTime, "HH:mm:ss").format("HH:mm A");
            plotLines.push(secondAppointment);
        }

        return plotLines;
    }
}