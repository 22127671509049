import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormArray, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { PatientService } from "../../services/patient.service";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";
import { PatientProfileService } from "./patient-profile.service";
import * as moment from "moment";
import sweetAlert from "sweetalert";
import { MatDialog } from '@angular/material';
import { DialogAppointmentComponent } from "../dialog-appointment/dialog-appointment.component";

@Component({
    selector: "app-patient-profile",
    templateUrl: "./patient-profile.component.html",
    styleUrls: ["./patient-profile.component.css"]
})
export class PatientProfileComponent implements OnInit
{
    form 		    : FormGroup;
    busy            : boolean  = false;
    patientId       : number;
    serverErrors    : string[] = [];
    patient         : any      = {};
    editableFields = {
        patientName     : false,
        comorbidities   : false,
        therapies       : false,
        treatmentHistory: false,
    };
    treatmentTitles : string[] = [
        "Topicals",
        "Corticosteroids",
        "PUVA / UVB",
        "Targeted Synthetic DMARDs",
        "NSAIDS",
        "Lifestyle changes",
        "Biologic Agents",
        "Conventional Synthetic DMARDs",
    ];
    options = ["Topicals", "Corticosteroids", "PUVA / UVB", "Targeted Synthetic DMARDs", "NSAIDS", "Lifestyle changes", "Biologic Agents", "Conventional Synthetic DMARDs"];
    therapyGoalsDetail : string[] = [
        "be free of pain",
        "be free of itching", 
        "no longer have burning sensations on skin",
        "be healed of all skin defects",
        "be able to sleep better",
        "feel less depressed",
        "experience a greater enjoyment of life",
        "have no fear that the disease will become worse",
        "be able to lead a normal everyday life",
        "be more productive in everyda",
        "be less of a burden to relatives and",
        "be able to engage in normal leisure activities",
        "be able to lead a normal working life",
        "be able to have more contact with other people",
        "be comfortable showing yourself more in public",
        "be less burdened in your partnership",
        "be able to have a normal sex life",
        "be less dependent on doctor and clinic visits need less time for daily",
        "treatment",
        "have fewer out-of-pocket treatment expenses",
        "have fewer side effects",
        "find a clear diagnosis and therapy",
        "have confidence in the therapy",
        "get better skin quickly",
        "regain control of the disease",
    ];

    constructor
    (
        private activatedRoute : ActivatedRoute,
        private router         : Router,
        private snackBar       : MatSnackBar,
        private patientService : PatientService,
        private errorResponse  : ErrorResponseHandlerService,
        private updateService  : PatientProfileService,
        private dialog         : MatDialog,
    )
    {
        this.buildForm();
    }
    
    ngOnInit()
    {
        this.initPage();
    }

    onSelectEvaluationOption(event)
    {
        if(event.value == '1')
        {
            let dialogRef = this.dialog.open(DialogAppointmentComponent, {
                width : "850px",
                data  : { patientId : this.patientId }
            });
        }
    }

    onSubmit()
    {
        if(this.form.valid)
        {
            this.busy    = true;
            let formData = this.formatFormData();
            
            this.updateService.updateProfile(formData).subscribe(response =>
            {
                this.busy = false;
                this.snackBar.open("Patient profile has been updated successfully", "Dismiss", {
                    duration: 6000
                });
            },
            (response : any) =>
            {
                this.busy  = false;
                this.serverErrors = this.errorResponse.handleHttpError(response);
            });
        }
        else
        {
            alert("Please fill out all fields");
        }
    }

    initPage()
    {
        this.activatedRoute.params.subscribe(params =>
        {
            this.getPatientData(params.patientId);
        });
    }
    
    getPatientData(patientId : number)
    {
        this.busy = true;

        this.patientService.getPatientDetails(patientId).subscribe(response =>
        {
            this.busy    = false;
            this.patient = response.json().patient;
            this.patient.therapies.forEach(therapy => this.addTherapy(therapy));
            this.patient.treatment_plans.forEach(history => this.addTreatmentHistory(history));
            this.form.get("patientId").setValue(patientId);
            this.form.get("firstName").setValue(this.patient.firstName);
            this.form.get("lastName").setValue(this.patient.lastName);
            this.form.get("comorbidities").setValue(this.patient.comorbidities);
        },
        (response : Response) =>
        {
            this.busy           = false;
            this.serverErrors = this.errorResponse.handleHttpError(response);

            if(response.status === 404)
            {
                this.router.navigate(["/app/patients"])
            }
        });
    }
    
    buildForm()
    {
        this.form = new FormGroup({
            patientId 	    : new FormControl("", Validators.required),
            firstName 	    : new FormControl("", Validators.required),
            lastName  	    : new FormControl("", Validators.required),
            comorbidities   : new FormControl(""),
            treatmentHistory : new FormArray  ([]),
            therapies       : new FormArray([]),
        });
    }

    addTreatmentHistory(history)
    {
        (<FormArray>this.form.get("treatmentHistory")).push(new FormGroup({
            treatmentId :   new FormControl(history.id, Validators.required),
            title     : new FormControl(history.treatmentTitle, Validators.required),
            startDate : new FormControl(history.therapyStartDate ?  history.therapyStartDate : null),
            endDate   : new FormControl(history.therapyEndDate ?  history.therapyEndDate : null),
        }));
    }

    addTherapy(therapy)
    {
        (<FormArray>this.form.get("therapies")).push(new FormGroup({
            therapyId      : new FormControl(therapy.id, Validators.required),
            therapy        : new FormControl(therapy.therapy, Validators.required),
            details        : new FormControl(therapy.details),
            startDate      : new FormControl(therapy.startDate, Validators.required),
            evaluationDate : new FormControl(therapy.evaluationDate),
            evaluationType : new FormControl(therapy.evaluationType, Validators.required),
            hasGoals       : new FormControl(false),
            goals          : new FormGroup({
                details           : new FormControl(therapy.goalDetails),
                anticipatedTiming : new FormControl(therapy.anticipatedTimingOfAchievingGoal),
                results           : new FormControl(therapy.results),
            }),
            futureOptionsShouldShow : new FormControl(therapy.futureOptionsShouldShow),
            futurePlanDetails       : new FormControl(therapy.futurePlanDetails),
            comments                : new FormControl(therapy.comments),
        }));
    }

    formatDate(date, format = "DD/MM/YYYY")
    {
        return date ? moment(new Date(date)).format(format) : null;
    }

    formatFormData()
    {
        let formData = this.form.value;

        formData.treatmentHistory.forEach(history =>
        {
            history.startDate = this.formatDate(history.startDate, "YYYY-MM-DD");
            history.endDate   = this.formatDate(history.endDate, "YYYY-MM-DD");
        });

        formData.therapies.forEach(therapy =>
        {
            therapy.startDate = this.formatDate(therapy.startDate, "YYYY-MM-DD");
            
            if(therapy.evaluationType == 3)
            {
                therapy.evaluationDate = this.formatDate(therapy.startDate, "YYYY-MM-DD");
            }
        });

        return formData;
    }

    deleteTherapy(therapy : FormGroup, index : number)
    {
        let therapyId = therapy.get("therapyId").value;
        let confirmed = confirm("Are you sure you want to remove this therapy?");

        if(confirmed)
        {
            this.busy = true;
            this.patientService.deleteTherapy(therapyId).subscribe(response =>
            {
                this.busy = false;
                sweetAlert("Therapy removed", "Therapy has been removed successfully", "success");
                (<FormArray>this.form.get("therapies")).removeAt(index);
            },
            (response : any) =>
            {
                this.busy  = false;
                this.serverErrors = this.errorResponse.handleHttpError(response);
            });
        }
    }
}
