import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { RegisterService } from "./register.service";

@Component({
	selector: "app-register",
	templateUrl: "./register.component.html",
	styleUrls: ["./register.component.css"]
})
export class RegisterComponent implements OnInit
{
	form 		   : FormGroup;
	busy 		   : boolean  = false;
	serverErrors   : string[] = [];
	accountCreated : boolean = false;

	constructor
	(
		private registerService : RegisterService,
	)
	{
		this.buildForm();
	}

	ngOnInit()
	{
	}

	onFormSubmit()
	{
		this.busy = true;
		this.registerService.register(this.form.value).subscribe(response =>
		{
			this.busy 			= false;
			this.accountCreated = true;
			this.serverErrors	= [];
			this.form.reset();
		},
		(response : any) =>
		{
			this.busy  = false;
			this.accountCreated = false;
			let errors = response.json();

			if(response.status === 400)
			{
				this.serverErrors = errors;
			}
			else
			{
				this.serverErrors = errors.message;
			}
		});
	}

	buildForm()
	{
		this.form = new FormGroup({
			firstName   : new FormControl("", Validators.required),
			lastName    : new FormControl("", Validators.required),
			email 	    : new FormControl("", [Validators.required, Validators.email]),
			password    : new FormControl("", Validators.required),
			inamiNumber : new FormControl("", Validators.required),
		});
	}
}
