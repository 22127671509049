import { Component, OnInit } from "@angular/core";
import { FormArray, FormGroup, FormControl, Validators } from "@angular/forms";
import { PatientService } from "../../services/patient.service";
import { Router } from "../../../../node_modules/@angular/router";
import { MatSnackBar } from '@angular/material';
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
// import sweetAlert from "sweetalert";

import * as moment from "moment";

@Component({
    selector: "app-add-patient",
    templateUrl: "./add-patient.component.html",
    styleUrls: ["./add-patient.component.css"]
})
export class AddPatientComponent implements OnInit
{
    form            : FormGroup;
    busy            : boolean = false;
    showMoreInfo    : boolean = false;
    serverErrors    : string[] = [];
    patientId       : number;
    treatmentTitles : string[] = [
        "Topicals",
        "Corticosteroids",
        "PUVA / UVB",
        "Targeted Synthetic DMARDs",
        "NSAIDS",
        "Lifestyle changes",
        "Biologic Agents",
        "Conventional Synthetic DMARDs",
    ];

    constructor
    (
        private patientService : PatientService,
        private router         : Router,
        private snackBar       : MatSnackBar,
        private errorResponse  : ErrorResponseHandlerService,
    )
    {
        this.buildForm();
    }

    ngOnInit()
    {
    }

    onFormSubmit()
    {
        if(this.form.valid)
        {
            this.busy = true;
            let formValue = this.formatData();

            this.patientService.submitPatientDetails(formValue).subscribe((response : any) =>
            {
                response            = response.json();
                this.busy           = false;
                this.serverErrors   = [];
                this.patientId      = response.patientId; 
                this.resetForm();
                this.snackBar.open("Patient has been created successfully", "Dismiss", {
                    duration: 6000
                });
                this.router.navigate(["/app/therapies/" + this.patientId]);
                    
            },
            (response : any) =>
            {
                this.busy         = false;
                this.serverErrors = this.errorResponse.handleHttpError(response);
            });
            
        }
    }

    formatData()
    {
        let formValue         = this.form.value;
        formValue.dateOfBirth = formValue.dateOfBirth ? moment(formValue.dateOfBirth).format("YYYY-MM-DD") : null;
        
        formValue.treatmentHistory.forEach(history =>
        {
            history.startDate = history.startDate ? moment(history.startDate).format("YYYY-MM-DD") : null;
            history.endDate   = history.endDate ? moment(history.endDate).format("YYYY-MM-DD") : null;
        });

        return formValue;
    }

    toggleMoreInfo()
    {
        this.showMoreInfo = !this.showMoreInfo;
    }

    buildForm()
    {
        this.form = new FormGroup({
            firstName        : new FormControl("", Validators.required),
            lastName         : new FormControl("", Validators.required),
            email            : new FormControl("", Validators.email),
            comorbidities    : new FormControl(""),
            phone            : new FormControl(""),
            gender           : new FormControl(""),
            dateOfBirth      : new FormControl(""),
            address          : new FormControl(""),
            treatmentHistory : new FormArray  ([])
        });
    }

    addTreatmentHistory()
    {
        (<FormArray>this.form.get("treatmentHistory")).push(new FormGroup({
            title     : new FormControl("", Validators.required),
            startDate : new FormControl(""),
            endDate   : new FormControl(""),
        }));
    }

    removeTreatmentHistory(index : number)
    {
        (<FormArray> this.form.get("treatmentHistory")).removeAt(index);
    }

    private resetForm()
    {
        let treatmentPlans : FormArray = <FormArray> this.form.get("treatmentHistory");
        let controlsLength = treatmentPlans.controls.length;

        for(let i = 0; i < controlsLength; i++)
        {
            this.removeTreatmentHistory(0);
        }

        this.form.reset();
    }
}
