import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PatientService } from "../../services/patient.service";
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { MatDialog, MatPaginator } from '@angular/material';
import { DialogConfirmComponent } from "../dialog-confirm/dialog-confirm.component";

import * as moment from "moment";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

interface Patient
{
	id	   	      : number;
	firstName     : string;
	lastName      : string;
	email 	      : string;
	phone 	      : string;
	gender	      : string;
	dateOfBirth   : string;
	address	   	  : string;
	comorbidities : string;
	created_at    : string;
	appointments  : Object;
}

@Component({
	selector: "app-patient-list",
	templateUrl: "./patient-list.component.html",
	styleUrls: ["./patient-list.component.css"]
})

export class PatientListComponent implements OnInit
{
	@ViewChild(MatPaginator) paginator: MatPaginator;
	busy			 : boolean = false;
	patients 		 : MatTableDataSource <Patient>;
	displayedColumns : string[] = ["firstName", "treatmentPlan", "viewAppointment", "action"];

	constructor
	(
		private patientService : PatientService,
		private router 		   : Router,
		private snackBar       : MatSnackBar,
		private dialog         : MatDialog,
		private errorResponse  : ErrorResponseHandlerService,
	)
	{
	}

	ngOnInit()
	{
		this.getAllPatients();
	}
	
	getAllPatients()
	{
		this.busy = true;
		
		this.patientService.getAllPatients().subscribe((response : any) =>
		{
			this.busy = false;
			this.patients = new MatTableDataSource(response.patients);
			this.patients.paginator = this.paginator;
		},
		(error : Response) =>
		{
			this.busy = false;
			this.errorResponse.handleHttpError(error);
		});
	}

	onDeleteBtnClick(patientId)
	{
		let dialogRef = this.dialog.open(DialogConfirmComponent, {
			// width : "350px",
			data  : { patientId }
		});

		dialogRef.afterClosed().subscribe((patientDeleted : boolean) =>
		{
			if(patientDeleted)
			{
				this.snackBar.open("Patient has been deleted successfully", "Dismiss", {
					duration: 6000
				});
	
				this.patients.data = this.patients.data.filter(patient => patient.id !== patientId);
			}
		});
	}

	applyFilter(filterValue: string)
	{
		this.patients.filter = filterValue.trim().toLowerCase();
	}

	hasAppointment(patient)
	{
		if(patient.appointment)
		{
			let now 				 = new Date();
			let firstAppointmentDate = new Date(patient.appointment.firstAppointmentDate);

			if(firstAppointmentDate > now)
			{
				patient.nextAppointmentDate = moment(firstAppointmentDate).format("DD/MM/YYYY");
				return true;
			}
			else if(patient.appointment.secondAppointmentType === "date")
			{
				let secondAppointmentDate = new Date(patient.appointment.secondAppointmentDate);
				
				if(secondAppointmentDate > now)
				{
					patient.nextAppointmentDate = moment(secondAppointmentDate).format("DD/MM/YYYY");
					return true;
				}
			}

			return false;
		}

		return false;
	}
}
