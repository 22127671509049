import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSelectChange } from "@angular/material";
import { PatientService } from "../../services/patient.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";

import * as moment from "moment";

@Component({
	selector: "app-appointments",
	templateUrl: "./appointments.component.html",
	styleUrls: ["./appointments.component.css"]
})
export class AppointmentsComponent implements OnInit
{
	@Input()  inDialog : boolean = false;
	@Input()  inDialogPatientId : number;
	@Output() formSubmit : EventEmitter<boolean> = new EventEmitter<boolean>();
	busy 		 : boolean  = false;
	serverErrors : string[] = [];
	isNewAccount : boolean  = false;
	form 	  	 : FormGroup;
	patientId    : number;

	constructor
	(
		private patientService : PatientService,
		private activatedRoute : ActivatedRoute,
		private router 		   : Router,
		private snackBar       : MatSnackBar,
		private errorResponse  : ErrorResponseHandlerService,
	)
	{
		this.buildForm();
	}

	ngOnInit()
	{
		this.activatedRoute.params.subscribe(params =>
		{
			this.patientId = params.patientId;
			this.form.get("patientId").setValue(params.patientId);
			this.isNewAccount = params.newAccount ? true : false;
		});
	}

	onFormSubmit()
	{
		if(this.inDialog)
		{
			this.form.get("patientId").setValue(this.inDialogPatientId);
		}

		if(this.form.valid)
		{
			this.busy = true;
			this.patientService.addAppointments(this.formatData()).subscribe(response =>
			{
				response  		  = response.json();
				this.busy 		  = false;
				this.serverErrors = [];
				this.form.reset();
				this.snackBar.open("Appointment been added successfully", "Dismiss", {
                    duration: 6000
				});

				if(!this.inDialog)
				{
					this.router.navigate(["/app/patientProfileData/" + this.patientId]);
				}
				else
				{
					this.formSubmit.emit(true);
				}
			},
			response =>
			{
				this.busy         = false;
                this.serverErrors = this.errorResponse.handleHttpError(response);
			});
		}
	}

	buildForm()
	{
		this.form = new FormGroup({
			patientId				: new FormControl(this.patientId, Validators.required),
			firstAppointmentDate	: new FormControl("", Validators.required),
			firstAppointmentTime	: new FormControl("", Validators.required),
			secondAppointmentType	: new FormControl("", Validators.required),
			secondAppointmentDate	: new FormControl(""),
			secondAppointmentTime	: new FormControl(""),
		});
	}
	
	formatData()
	{
		let formData = this.form.value;
		formData.firstAppointmentDate = moment(formData.firstAppointmentDate).format("YYYY-MM-DD");
		formData.firstAppointmentTime = moment(formData.firstAppointmentTime, "h:m A").format("HH:mm:ss");
		
		if(formData.secondAppointmentType === "date")
		{
			formData.secondAppointmentDate = moment(formData.secondAppointmentDate).format("YYYY-MM-DD");
			formData.secondAppointmentTime = moment(formData.secondAppointmentTime, "h:m A").format("HH:mm:ss");
		}

		return formData;
	}

	onSecondAppointmentTypeChange(event : MatSelectChange)
	{
		if(event.value === "date")
		{
			this.form.get("secondAppointmentDate").setValidators(Validators.required);
			this.form.get("secondAppointmentTime").setValidators(Validators.required);
		}
		else
		{
			this.form.get("secondAppointmentDate").clearValidators();
			this.form.get("secondAppointmentTime").clearValidators();
			this.form.get("secondAppointmentDate").setValue(null);
			this.form.get("secondAppointmentTime").setValue(null);
		}

		this.form.get("secondAppointmentDate").updateValueAndValidity();
		this.form.get("secondAppointmentTime").updateValueAndValidity();
	}
}
