import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ProfileService
{
	private UPDATE_PROFILE_URL = API_BASE_URL + "account/updateProfile";
	private GET_PROFILE_URL    = API_BASE_URL + "account/profile";
	private LOGOUT_URL    	   = API_BASE_URL + "account/logout";
	public  nameSubject : Subject <string> = new Subject <string>();

	constructor(private http : Http)
	{ 
	}

	getProfile()
	{
		let headers = new Headers({
			"Authorization" : "Bearer " + localStorage.getItem("token")
		});

		return this.http.get(this.GET_PROFILE_URL, { headers }).pipe (
			map(el => el.json())
		);
	}

	updateProfile(params)
	{
		let headers = new Headers({
			"Authorization" : "Bearer " + localStorage.getItem("token")
		});

		return this.http.post(this.UPDATE_PROFILE_URL, params, {headers}).pipe (
			map(el => el.json())
		);
	}

	logout()
	{
		let headers = new Headers({
			"Authorization" : "Bearer " + localStorage.getItem("token")
		});

		return this.http.get(this.LOGOUT_URL, {headers}).pipe (
			map(el => el.json())
		);
	}
}
