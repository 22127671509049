import { Injectable } from "@angular/core";
import { Http, Headers } from "@angular/http";
import { API_BASE_URL } from "../../constants/api.constants";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class PatientProfileService
{
	private UPDATE_PATIENT_URL = API_BASE_URL + "patient/updatePatient";

	constructor(private http : Http)
	{ 
	}

	updateProfile(params)
	{
		return this.http.post(this.UPDATE_PATIENT_URL, params, {
			headers : new Headers({
				"Authorization" : "Bearer " + localStorage.getItem("token")
			})
		})
		.pipe(map(el => el.json()))
	}
}
