import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ProfileService } from "./profile.service";
import { Router } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

@Component({
	selector: "app-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.css"]
})
export class ProfileComponent implements OnInit
{
	form 		   : FormGroup;
	firstTimeLogin : boolean  = false;
	serverErrors   : string[] = [];
	busy		   : boolean  = false;
	accountUpdated : boolean  = false;
	userEmail	   : string	  = "";

	constructor
	(
		private profileService : ProfileService,
		private router 		   : Router,
		private snackBar       : MatSnackBar,
		private errorResponse  : ErrorResponseHandlerService,
	)
	{
		this.buildForm();
	}

	ngOnInit()
	{
		this.firstTimeLogin = Boolean(localStorage.getItem("firstTimeLogin"));
		this.getAccountDetails();
	}

	getAccountDetails()
	{
		this.busy = true;

		this.profileService.getProfile().subscribe(response =>
		{
			this.busy = false;
			this.form.get("firstName").setValue(response.firstName);
			this.form.get("lastName").setValue(response.lastName);
			this.form.get("language").setValue(response.language);
			this.form.get("designation").setValue(response.designation);
			this.userEmail = response.email;
			this.profileService.nameSubject.next(response.firstName + " " + response.lastName);
		},
		(error : Response) =>
		{
			this.busy = false;
			this.serverErrors = this.errorResponse.handleHttpError(error);
		});
	}

	onFormSubmit()
	{
		this.busy = true;

		this.profileService.updateProfile(this.form.value).subscribe(response =>
		{
			this.busy  						 = false;
			this.accountUpdated 			 = true;
			this.serverErrors				 = [];
			
			localStorage.setItem("firstTimeLogin", "false");
			this.snackBar.open("Your profile has been updated successfully", "Dismiss", {
				duration: 6000
			});

			this.router.navigate(["/app/patients"])
		},
		(response : any) =>
		{
			this.busy  = false;
			this.accountUpdated = false;
			this.serverErrors = this.errorResponse.handleHttpError(response);
		});
	}

	buildForm()
	{
		this.form = new FormGroup({
			firstName 	 : new FormControl("", Validators.required),
			lastName  	 : new FormControl("", Validators.required),
			designation  : new FormControl("", Validators.required),
			language  	 : new FormControl("", Validators.required),
		});
	}
}
