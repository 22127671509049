import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { PatientService } from "../../services/patient.service";

@Component({
	selector: "app-dialog-confirm",
	templateUrl: "./dialog-confirm.component.html",
	styleUrls: ["./dialog-confirm.component.css"]
})
export class DialogConfirmComponent implements OnInit
{
	patientId : number;

	constructor
	(
		@Inject(MAT_DIALOG_DATA) private data : { patientId : number },
		private dialogRef 	   : MatDialogRef <DialogConfirmComponent>, 
		private patientService : PatientService,
	)
	{
		this.patientId = data.patientId;
	}

	ngOnInit()
	{
	}

	onConfirmBtnClick()
	{
		this.patientService.deletePatient(this.patientId).subscribe(response =>
		{
			this.dialogRef.close(true);
		},
		() =>
		{
			this.dialogRef.close(false);
		});
	}
}
