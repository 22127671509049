import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ProfileService } from "../pages/profile/profile.service";

@Injectable({
	providedIn: "root"
})
export class ProfileSetGuard implements CanActivate
{
	constructor(private profileService : ProfileService, private router : Router)
	{

	}

	canActivate (
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) : Observable<boolean> | Promise<boolean> | boolean
	{
		if(localStorage.getItem("firstTimeLogin") === "true")
		{
			this.router.navigate(["/app/profile"]);
		}

		return true;
	}
}
