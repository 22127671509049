import { Component, OnInit } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ProfileService } from "../profile/profile.service";
import { Router } from "@angular/router";
import { ErrorResponseHandlerService } from "../../services/error-response-handler.service";
import { Response } from "@angular/http";

@Component({
	selector: "app-main-nav",
	templateUrl: "./main-nav.component.html",
	styleUrls: ["./main-nav.component.css"]
})
export class MainNavComponent implements OnInit
{
	public show		: boolean = true;
	public fullName : string  = "";

	isHandset$: Observable<boolean>
	
	constructor
	(
		private breakpointObserver  : BreakpointObserver,
		private profileService	    : ProfileService,
		private errorHandlerService : ErrorResponseHandlerService,
		private router	   		    : Router,
	)
	{
		this.observeBreakpoint();
	}

	ngOnInit()
	{
		this.profileService.getProfile().subscribe(response =>
		{
			this.fullName = response.firstName + " " + response.lastName;
		},
		(error : Response) =>
		{
			this.errorHandlerService.handleHttpError(error);
		});
	}

	observeBreakpoint()
	{
		this.isHandset$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe (
			map(result => result.matches)
		);
	}

	toggle()
	{
		this.show = !this.show;
	}
}

